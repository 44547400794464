export const RoutePaths = {
    home: "/",
    aboutus: "/about",
    service:"/service",
    career: "/career",
    contactus: "/contact-us",
  
}

export const PageRouteLinks = [
    {
        id: 'HOME',
        label: 'HOME',
        path: RoutePaths.home
    }, 
    {
        id: "ABOUT",
        label: "ABOUT",
        path: RoutePaths.aboutus
    },
    {
        id: "SERVICE",
        label: "SERVICE",
        path: RoutePaths.service
    },
    {
        id: "CAREER",
        label: "CAREER",
        path: RoutePaths.career
    },
    {
        id: "CONTACT US",
        label: "CONTACT US",
        path: RoutePaths.contactus
    },
    
];