import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PageRoute from './Components/routing/PageRoute';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  appContainer: {
    "&.MuiContainer-root":{
      width:"100%",
      maxWidth:"100%",
      paddingLeft: "0px",
      paddingRight: "0px",
    }
  }
}));

function App() {
  const classes = useStyle();
  return (
    <div>
     
      
      <Container className={classes.appContainer}>
      <PageRoute />
    </Container>
    
    </div>
  );
}

export default App;
