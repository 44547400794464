import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Grid, Card, Button } from "@mui/material";
import { Images } from "../assets/Image/index";
import { fontSize } from "@mui/system";
import bgImgCareer from "../assets/Image/bgImgCareer.jpg"

const useStyle = makeStyles(() => ({
  BgImg: {
    // backgroundImage: `url(${bgImgCareer})`,
    margin: "auto",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // padding:"50px"
  },
  heading: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: "3rem",
      paddingTop: "3%",
      paddingBottom: "3%",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.5rem",
          paddingTop: "1.5%",
          paddingBottom: "1.5%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "3rem",
          paddingTop: "3%",
          paddingBottom: "3%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "3rem",
          paddingTop: "3%",
          paddingBottom: "3%",
        },
      },
    },
  },
  para: {
    "&.MuiTypography-root": {
      padding: "3%",
      paddingLeft: "10%",
      fontSize: "1.1rem",
      fontWeight: "500",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          padding: "1%",
          paddingLeft: "2%",
          fontSize: "0.6rem",
          fontWeight: "300",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          padding: "3%",
          paddingLeft: "2%",
          fontSize: "1.1rem",
          fontWeight: "400",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          padding: "3%",
          paddingLeft: "4%",
          fontSize: "1.1rem",
          fontWeight: "500",
        },
      },
    },
  },
  CareerImg: {
    width: "100%",
    background:"transparent",
    height:"auto",
    backgroundRepeat: "no-repeat",
    borderRadius: "10%",
    padding:"5%",
    marginTop:"-4rem",
    // [`@media screen and (min-width:0px) and (max-width:250px)`]: {
    //   borderRadius: "6%",
    //   padding:"2%",
    //   marginTop:"-3rem",
    // },

    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      borderRadius: "6%",
      padding:"2%",
      marginTop:"-1rem",
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      padding:"3%",
      marginTop:"-1rem",
    },
    [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
      padding:"3%"
    },
  },
  JobListing: {
    "&.MuiTypography-root": {
      fontSize: "2rem",
      fontWeight: "550",
      paddingBottom: "3%",
      // paddingLeft: "13%",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.8rem",
          fontWeight: "550",
          paddingBottom: "3%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2rem",
          fontWeight: "550",
          paddingBottom: "3%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2rem",
          fontWeight: "550",
          paddingBottom: "3%",
        },
      },
    },
  },
  BoxStyle: {

    "&.MuiBox-root": {
      padding: "10%",
      marginTop:"-10rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          padding: "1%",
          marginTop:"-2rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          padding: "2%",
          marginBottom: "2.5rem",
          // marginTop:"-2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
          padding: "3%",
          marginBottom: "2.5rem",
          // marginTop:"-5rem",
        },
      },
    }
  },
  CardStyle: {
    padding: "1%",
    marginBottom: "3%"
  },
  CardBox: {
    "&.MuiBox-root": {
      marginLeft: "5rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          marginLeft: "1rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          marginLeft: "2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
          marginLeft: "4rem",
        },
      },
    }
  },

  JobTitle: {
    "&.MuiTypography-root": {
      fontSize:"1.1rem",
      marginRight:"10rem",


      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.8rem",
          marginRight:"3rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1rem",
          marginRight:"2rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          marginRight:"5rem"
        },
      },
    },

  },
  JobHeading: {
    "&.MuiTypography-root": {
      fontSize:"1.1rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.8rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {

        },
      },
    },

  },
  JobPara: {
    "&.MuiTypography-root": {
      // textAlign:"center",
      fontSize:"1rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.7rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.9rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {

        },
      },
    },

  },
  buttonStyle: {
    "&.MuiButton-root": {
      borderRadius: "2rem",
      alignItem: "center",
      marginTop: "0.4rem",
      marginLeft:"10rem",
      [`@media screen and (min-width:0px) and (max-width:400px)`]: {
        "&.MuiButton-root": {
          fontSize:"0.5rem",
          borderRadius: "1rem",
          marginTop: "0.1rem",
          width:"100%",
          padding:"2%",
          marginLeft:"0%"
          
        }
      },
      [`@media screen and (min-width:401px) and (max-width:480px)`]: {
        "&.MuiButton-root": {
          fontSize:"0.5rem",
          borderRadius: "1rem",
          marginTop: "0.1rem",
          width:"100%",
          padding:"5%",
          marginLeft:"30%"
          
        }
      },
      [`@media screen and (min-width:481px) and (max-width:580px)`]: {
        "&.MuiButton-root": {
          fontSize:"0.7rem",
          borderRadius: "1rem",
          marginTop: "0.7rem",
          width:"120%",
          padding:"3%",
          marginLeft:"40%"
        }
        
      },
      [`@media screen and (min-width:581px) and (max-width:768px)`]: {
        "&.MuiButton-root": {
          fontSize:"0.7rem",
          borderRadius: "1rem",
          marginTop: "0.7rem",
          width:"120%",
          padding:"5%",
          marginLeft:"100%"
        }
        
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiButton-root": {
          marginLeft:"10rem"
        },
      },
      
    },

  },
}));

const Career = () => {
  const classes = useStyle();
  return (
    <Box className={classes.BgImg}>
      <Box>
        <Typography className={classes.heading}>Career</Typography>

        <Grid container spacing={2}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Box>
              <Typography className={classes.para}>
                Working for synergiHub requires a passion for
                entrepreneurship, innovation, problem-solving, and Monopoly.
                Okay maybe not the last one, but it doesn't hurt if you know the
                best way to win doesn't involve Boardwalk or Park Place.
              <br/>
              <br/>
                We are always looking for driven, interesting, and capable
                individuals to join our team. In addition to our agency
                business, we have several in-house projects and companies.
                If you think you fit the bill, then we'd like to chat.
              </Typography>

              <Typography className={classes.para}>
                <b> Check out the opportunities below. </b>
              </Typography>
            </Box>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <img className={classes.CareerImg} src={Images.Career} />
          </Grid>
        </Grid>
      </Box>



{/* <....................................Job Listings...............................................................> */}



      <Box className={classes.BoxStyle}>
        <Typography className={classes.JobListing}>Job Listings</Typography>

        <Card className={classes.CardStyle}>
          <>
            <Box style={{ display: "flex" }}>
              <Box>
                <Typography className={classes.JobTitle} > <b>Web Development</b></Typography>
                {/* <Typography  >We create web pages with content for your website,in...</Typography> */}
              </Box>
              <Box className={classes.CardBox}>
                <Typography className={classes.JobHeading}><b>Location</b></Typography>
                <Typography className={classes.JobPara}>Pune</Typography>
              </Box>
              <Box className={classes.CardBox}>
                <Typography className={classes.JobHeading}><b>Posted</b></Typography>
                <Typography className={classes.JobPara}>2 month ago</Typography>
              </Box>
              <Box >
                <Button variant="contained" color="success" href="mailto:contact@3techsolution.io" target="_blank" className={classes.buttonStyle}>
                  Apply
                </Button>
              </Box>
            </Box>

          </>
        </Card>


        <Card className={classes.CardStyle}>
          <>
            <Box style={{ display: "flex",  }}>
              <Box>
                <Typography className={classes.JobTitle}> <b>App Development</b></Typography>
                {/* <Typography  >Design and develop of mobile applications for Android...</Typography> */}
              </Box>
              <Box className={classes.CardBox}>
                <Typography className={classes.JobHeading}><b>Location</b></Typography>
                <Typography className={classes.JobPara}>Pune</Typography>
              </Box>
              <Box className={classes.CardBox}>
                <Typography className={classes.JobHeading}><b>Posted</b></Typography>
                <Typography className={classes.JobPara}>1 month ago</Typography>
              </Box>
              <Box >
                <Button variant="contained" color="success" href="mailto:contact@3techsolution.io" target="_blank" className={classes.buttonStyle}>
                  Apply
                </Button>
              </Box>
            </Box>

          </>
        </Card>
      </Box>
    </Box>
  );
};

export default Career;