import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import emailjs from "emailjs-com";
import { Box, Typography, Grid, Button, Card, TextField } from "@mui/material";
import bg from "../assets/Image/bg.jpg";

const useStyle = makeStyles(() => ({

  bgStyle:{
    // backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
  backgroundRepeat: "no-repeat",


  },

  formStyle: {
    "&.MuiBox-root": {
      width: "100%",
      marginLeft: "10%",
      padding: "20px",
      


      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          
          marginLeft: "0%",
          padding: "0px",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          marginLeft: "0rem",
          padding: "0px",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
          marginLeft: "0rem",
        },
      },
    },
  },
  FormBox: {
    "&.MuiBox-root": {
      width: 400,
      maxWidth: "100%",
      height: "5%",
      margin: "5%",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          maxWidth: "90%",
          height: "0%",
          margin: "3%",
        }
      },
     
    },
  },
  FormButton: {
    "&.MuiButton-root": {
      marginLeft: "3%",
      marginTop: "5%",
      // padding:"10px 15px",
      borderRadius: "1rem",
      backgroundColor: "#f97b08",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiButton-root": {

        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiButton-root": {
          marginLeft: "5%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiButton-root": {
          marginLeft: "5%",
        },
      },
    },
  },
  contactInfo: {
    "&.MuiTypography-root": {
      fontSize: "2.3rem",
      marginTop: "6rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.5rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.8rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2.2rem",
        },
      },
    },
  },

  contactBox: {
    "&.MuiBox-root": {
      
      width:"100%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
         padding:"1rem",
         width:"90%"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          padding:"1rem",
         width:"90%",
         marginLeft:"1.5rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
          padding:"1rem",
          marginLeft:"2rem"
        },
      },
    },
  },
  India: {
    "&.MuiTypography-root": {
      marginTop: "2%",
      marginBottom:"2%",
      fontWeight: "900",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
          marginTop: "0%",
        }
      },
     
    },
  },
  contactText: {
    "&.MuiTypography-root": {
      fontSize: "1.1rem",
      marginRight: "12rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.7rem",
          marginRight: "1rem",

        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
          marginRight: "8rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          marginRight: "22rem",
        },
      },
    },
  },
  contactText1: {
    "&.MuiTypography-root": {
      fontSize: "1.1rem",
      marginLeft: "-40px",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.7rem",

        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
        }
      },
     
    },
  },


}))



const Contactus = () => {
  const classes = useStyle();


  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8zyorol",
        "template_d40dagh",
        e.target,
        "1VxOdL64uYsR_RCn3"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };
  return (
    <Box className={classes.bgStyle}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12} sm={12} xs={12} style={{ marginTop: "5%" }}>
          <Box className={classes.formStyle}  >
            <form onSubmit={sendEmail} >
              <Box className={classes.FormBox}
              >
                <TextField fullWidth required type="text" name="name" label="NAME" id="Name" />
              </Box>

              <Box className={classes.FormBox} >
                <TextField fullWidth type="email" name="email" required label="EMAIL ADDRESS" id="EMAIL ADDRESS " />
              </Box>
              <Box
                className={classes.FormBox}
              >
                <TextField type="text" name="phone" required fullWidth label="PHONE" id="PHONE" />
              </Box>
              <Box
                className={classes.FormBox}
              >
                <TextField type="text" name="message" required fullWidth label="PURPOSE" id="PURPOSE" />
              </Box>
              <Button className={classes.FormButton} type="submit" variant="contained" href="#contained-buttons">
                Send Message
              </Button>
            </form>

          </Box>

        </Grid>


        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Box className={classes.contactBox}>
            <Typography className={classes.contactInfo}>
              <b>Contact Info</b>
            </Typography>

            <hr />
            <Box >
              <Typography className={classes.India} >INDIA</Typography>
              <Box style={{ display: "flex" }}>
                <Box >
                  <Typography className={classes.contactText}>+91 7744841379</Typography>
                  <Typography className={classes.contactText}>contact@synergihub.in</Typography>
                </Box>
                <Box >
                  {/* <Typography className={classes.contactText1}> +91 7744841379 </Typography> */}
                  <Typography className={classes.contactText1}></Typography>
                </Box>
              </Box>

              <hr />

              <Box style={{ textAlign:"start" }}>
                <Box >
                  <Typography className={classes.contactText}>Office:</Typography>
                  <Typography className={classes.contactText}>At Baner, Tal Haweli, Dist. Pune 410501</Typography>


                </Box>
               
              </Box>
            </Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Contactus;