import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import appdev from "../assets/Image/Service/appdev.png";
import digital from "../assets/Image/Service/digital.png";
import mobdev from "../assets/Image/Service/mobdev.png";
import productserv from "../assets/Image/Service/productserv.png";
import webdev from "../assets/Image/Service/webdev.png";
import background from "../assets/Image/Service/background.jpg";
import softwaretesting from "../assets/Image/Service/softwaretesting.jpg";
import { makeStyles } from "@mui/styles";
import ServiceBgImg from "../assets/Image/Service/BgImg.jpg"

const useStyle = makeStyles(() => ({
  title: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: "3rem",
      paddingTop: "3%",
      paddingBottom: "3%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.3rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2.5rem",
        },
      },
    },
  },
  appimg: {
    width: "80%",
    marginTop: "70px",
    marginLeft: "17%",
    borderRadius: "5%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width: "65%",
      marginTop: "3rem",
      height:"80%",

    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width: "65%",
      marginTop: "4rem",
      height:"80%",
    },
    [`@media screen and (min-width:769px) and (max-width:899px)`]: {
      width: "65%",
      marginTop: "3rem",
      height:"80%",
    },
  },
  appimg1: {
    width: "80%",
    marginTop: "70px",
    marginLeft: "-10%",
    borderRadius: "5%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {

      width: "65%",
      marginTop: "10px",
      marginLeft: "18%",
      height:"80%",
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width: "65%",
      marginTop: "50px",
      marginLeft: "18%",
      height:"70%",
    },
    [`@media screen and (min-width:769px) and (max-width:899px)`]: {
      width: "65%",
      marginTop: "50px",
      marginLeft: "18%",
      height:"70%",
      
    },
  },
  apptxt: {
    "&.MuiTypography-root": {
      marginTop: "11%",
      textAlign: "left",
      fontSize: "1.5rem",
      marginLeft: "10%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.8rem",
          marginTop: "1%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.3rem",
          marginTop: "0%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:899px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.4rem",
          marginTop: "0%",
        },
      },
    },
  },
  apptxt1: {
    "&.MuiTypography-root": {
      marginTop: "11%",
      textAlign: "left",
      fontSize: "1.5rem",
      marginLeft: "10%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.8rem",
          marginTop: "1%",
          marginTop: "15%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.3rem",
          marginTop: "0%",
          marginTop: "15%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:899px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.4rem",
          marginTop: "0%",
          marginTop: "15%",
        },
      },
    },
  },
  paraText: {
    "&.MuiTypography-root": {
      textAlign: "left",
      width: "80%",
      marginTop: "5%",
      fontSize: "1.2rem",
      marginLeft: "10%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.7rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
          marginTop: "3%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:899px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.1rem",
          marginTop: "3%",
        },
      },
    }
  },
  // readbtn: {
  //   "&.MuiButton-root": {
  //     marginLeft: "40%",
  //     marginTop: "1%",
  //     width: "12%",
  //     height: "8vh",
  //   },
  // },
  // readbtn1: {
  //   "&.MuiButton-root": {
  //     marginLeft: "6%",
  //     marginTop: "0%",
  //     width: "12%",
  //     height: "8vh",
  //   },
  // },
  horizontal: {
    margin: "auto",
    width: "90%",
    marginTop: "10%",
  },
  main: {
    // backgroundImage: `url(${ServiceBgImg})`,
    // margin: "auto",
    // height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

const Services = () => {
  const classes = useStyle();
  return (
    <Box className={classes.main}>
      <Typography className={classes.title}>Our Services</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img className={classes.appimg} src={appdev} alt="" />
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12} >
            <Typography className={classes.apptxt}>
              APPLICATION DEVELOPMENT
            </Typography>
            <Typography className={classes.paraText}

            >
              synergiHub IT Solution team has extensive experience in design and
              development of both desktop based and web application. We are more
              inclined towards web application as it enables scalability to
              applications. We have worked on different domains like education,
              online training, logistics, process management and delivered
              multiple custom applications to clients. We have extensive
              experience in third party and social integration.
            </Typography>
          </Grid>
        </Grid>
        {/* <Button variant="contained" className={classes.readbtn}>
          Read More
        </Button> */}
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt1}>
              ANDROID APP DEVELOPMENT
            </Typography>
            <Typography
              className={classes.paraText}
            >
              With the world moving towards digital evolution and mobile apps
              leading it, mobile app development is most crucial factor in
              digital transformation. We provide best in class mobility services
              and solutions with complete mobility lifecycle consultation and
              custom app development services. We have expertise in native app
              development using Android and iOS API, also has extensive
              experience on working with hybrid frameworks PhoneGap, ionic etc.
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img className={classes.appimg1} src={mobdev} alt="" />
          </Grid>
        </Grid>
        {/* <Button variant="contained" className={classes.readbtn1}>
          Read More
        </Button> */}
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img className={classes.appimg} src={webdev} alt="" />
          </Grid>
          <Grid itemlg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt}>
              WEBSITE DESIGN AND DEVELOPMENT
            </Typography>
            <Typography
              className={classes.paraText}
            >
              We understand need of online presence and provide complete support
              in design and development of business websites. Though we recently
              started working on website design and development, our design and
              development team has extensive experience in the field. We are
              well versed with WordPress, Shopify and have delivered couple of
              high class and customized website development solutions.
            </Typography>
          </Grid>
        </Grid>
        {/* <Button variant="contained" className={classes.readbtn}>
          Read More
        </Button> */}
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt1}>
              PRODUCT SOFTWARE DEVELOPMENT
            </Typography>
            <Typography
              className={classes.paraText}
            >
              Building software or a product requires deep expertise in
              technologies such as mobile, cloud, social, and analytics. Our
              leadership team was involved in development of many such products
              and applications. synergiHub IT Solution Software provides end to end
              product engineering services from design, development to
              maintenance and support.
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img className={classes.appimg1} src={productserv} alt="" />
          </Grid>
        </Grid>
        {/* <Button variant="contained" className={classes.readbtn1}>
          Read More
        </Button> */}
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img className={classes.appimg} src={digital} alt="" />
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt}>
              DIGITAL MARKETING / SEO
            </Typography>
            <Typography
              className={classes.paraText}
            >
              Digital Marketing is the fastest way of reaching your target
              customer using digital technologies like internet. It consists of
              marketing and optimization of different social media platform,
              online business. With number of smart phone users and access to
              internet growing every moment, digital marketing is need of hour
              for any business.
            </Typography>
          </Grid>
        </Grid>
        {/* <Button variant="contained" className={classes.readbtn}>
          Read More
        </Button> */}
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt1}>
              SOFTWARE TESTING SEREVICES
            </Typography>
            <Typography
              className={classes.paraText}
            >
              synergiHub IT Solution is a privately owned Software QA and Software
              Testing Services Company, and IT Support business formed in 2024.
              Today we’re proud to boast a strong team of certified QA engineers
              who thrive on rolling up their sleeves and solving your QA,
              Testing, and IT problems and meeting your business needs. We are
              on a mission to exceed your expectations and form a long-term,
              mutually beneficial relationship with you.
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img className={classes.appimg1} src={softwaretesting} alt="" />
          </Grid>
        </Grid>
        {/* <Button
          variant="contained"
          className={classes.readbtn1}
          style={{ marginTop: "2%" }}
        >
          Read More
        </Button> */}
      </Box>
    </Box>
  );
};

export default Services;