import React from "react";
import { makeStyles } from "@mui/styles";
import Carousel from "react-bootstrap/Carousel";
import web from "../assets/Image/web2.gif";
import app from "../assets/Image/mob1.gif";
import software from "../assets/Image/soft2.gif";
import dotsquare from "../assets/Image/dotsquare.jpg";
import { Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Images } from "../assets/Image/index";
// import bg from "../assets/Image/bg.jpg";

const useStyle = makeStyles(() => ({

  CardTitle: {
    "&.MuiTypography-root": {
      marginTop: "5%",
      fontSize: "1.7rem",
      textAlign: "center",
      fontWeight: "550",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.4rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {

        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {

        },
      },
    }
  },
  CasePara: {
    "&.MuiTypography-root": {
      fontSize: "1.2rem",
      margin: "2%",
      textAlign: "center",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.1rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {

        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {

        },
      },
    }
  },
  IconStyle: {
    width: "50%",
    padding: "2rem",
    alignContent:"center",
    marginLeft:"5rem",
    [`@media screen and (min-width:0px) and (max-width:375px)`]: {
      padding: "1rem",
     width: "50%",
     marginLeft:"4rem",
   },
    [`@media screen and (min-width:376px) and (max-width:480px)`]: {
       padding: "1rem",
      width: "50%",
      marginLeft:"5rem",
    },
    [`@media screen and (min-width:481px) and (max-width:590px)`]: {
      padding: "1rem",
      width: "50%",
      marginLeft:"7rem",
    },
    [`@media screen and (min-width:591px) and (max-width:768px)`]: {
      padding: "1rem",
      width: "50%",
      marginLeft:"10rem",
    },
    [`@media screen and (min-width:769px) and (max-width:900px)`]: {
      marginLeft:"10rem",
    },
  },
  BuildTitle: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: "1.3rem",
      color: " #f97b08",
      paddingTop: "5%",
      fontWeight: "500",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.2rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {

        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {

        },
      },
    }
  },
  BuildTitle1: {
    "&.MuiTypography-root": {
      textAlign: "center",
      paddingTop: "0.5%",
      fontSize: "2.5rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {

        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {

        },
      },
    }
  },
  ItBox: {

    "&.MuiBox-root": {
      marginTop: "-2rem",
      padding: "1%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          marginTop: "0rem",
          padding: "1.5rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          marginTop: "0rem",
          padding: "2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {

        },
      },
    }

  },
  ItImage: {
    width: "100%",
    padding: "1rem",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {


    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      padding: "2rem",
    },
    [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
      padding: "3rem",
    },
  },
  ItTitle: {
    "&.MuiTypography-root": {
      fontSize: "3rem",
      paddingLeft: "1rem",
      fontWeight: "500",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.5rem",

        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2.5rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {

        },
      },
    }
  },
  ItPara: {
    "&.MuiTypography-root": {
      fontSize: "1.1rem",
      paddingLeft: "1rem",
      color: "gray",
      marginTop: "2%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {

        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {

        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {

        },
      },
    }
  },
  // <....................................................Integration...................................>
  BoxStyle: {

    "&.MuiBox-root": {
      height: "100vh",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      marginTop: "10%",
      [`@media screen and (min-width:0px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
          display: "none",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {

        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {

        },
      },
    }

  },
  IntegrationTital: {
    "&.MuiTypography-root": {
      color: "#f97b08", fontSize: "23px", fontWeight: "600",

    },
  },

  IntegrationTital1: {
    "&.MuiTypography-root": {
      fontSize: "42px",

    },
  },
  Angular: {
    borderRadius: "50%",
    marginTop: "-40%",
    width: "5%",
    marginLeft: "10%",

  },
  Php: {
    borderRadius: "50%",
    marginTop: "-35%",
    width: "4%",
    marginLeft: "10%",

  },
  Android: {
    borderRadius: "50%",
    marginTop: "-10%",
    width: "5%",
    marginLeft: "-25%",

  },
  Aws: {
    borderRadius: "50%",
    marginTop: "-12%",
    width: "6%",
    marginLeft: "10%",

  },
  Swift: {
    borderRadius: "50%",
    marginTop: "15%",
    width: "5%",
    marginLeft: "-17%",

  },
  Net: {
    borderRadius: "50%",
    marginTop: "10%",
    width: "5%",
    marginLeft: "13%",

  },
  Ui: {
    borderRadius: "50%",
    marginTop: "-35%",
    width: "5%",
    marginLeft: "38%",

  },
  Qa: {
    borderRadius: "50%",
    marginTop: "-42%",
    width: "5%",
    marginLeft: "10%",

  },
  App: {
    borderRadius: "50%",
    marginTop: "-35%",
    width: "8%",
    marginLeft: "89%",
  },
  Mysql: {
    borderRadius: "50%",
    marginTop: "-38%",
    width: "5%",
    marginLeft: "74%",
  },

  Python: {
    borderRadius: "50%",
    marginTop: "-10%",
    width: "5%",
    marginLeft: "7%",
  },

  Digit: {
    borderRadius: "50%",
    marginTop: "-20%",
    width: "9%",
    marginLeft: "65%",
  },

  MobViewBox: {

    "&.MuiBox-root": {
      marginTop:"4rem",
      [`@media screen and (min-width:1024px) and (max-width:3024px)`]: {
        "&.MuiBox-root": {
         display:"none"
        }
      },
      
    }

  },

  MobViewIcon:{
    width:"20%",
    borderRadius: "50%",
    padding:"1rem"
  },
  IntegrationTital2: {
    "&.MuiTypography-root": {
      textAlign:"center",color:"blue",fontSize:"2rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
         fontSize:"1.3rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.7"
        }
      },
    },
  },
  IntegrationTital3: {
    "&.MuiTypography-root": {
      textAlign:"center",fontSize:"2.3rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
         fontSize:"1.5rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"2",
        }
      },

    },
  },

}));

const IconStyle = {
  marginLeft: "10rem",

  // [`@media screen and (min-width:0px) and (max-width:480px)`]: {
  //   marginLeft:"5rem",
  // },
  // [`@media screen and (min-width:281px) and (max-width:768px)`]: {

  // },
  // [`@media screen and (min-width:769px) and (max-width:1024px)`]: {

  // },

};
const BoxStyle1 = {
  marginLeft: "2rem",
  marginTop: "4rem",
}

const myStyle = {
  padding: "0.5rem"
};
const webImage = {
  width: "70%",
  height: "auto",
  marginLeft:"10%",
  backgroundSize: "cover",
  marginTop: "-1rem",
};
const WebText = {
  color: "#f97b08",
  paddingTop: "2rem",
  marginLeft: "2rem",
  fontSize: "3rem",
  fontWeight: "600",
  // width: "100%",
};
const text = {
  fontSize: "1.2rem",
  marginLeft: "2rem",
  paddingTop: "1rem",
  fontWeight: "450",
};





const Home = () => {
  const classes = useStyle();
  return (

    <Box style={myStyle}>
      <Carousel style={{ padding: "1%" }}>
        <Carousel.Item>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12} fullWidth>
              <h2 style={WebText}>Web Design & Development</h2>
              <p style={text}>
                Do you need to design a website? We do professional web design
                with search engine optimization in the main search engines. We
                create web pages with content for your website, including
                images, multilingual translations, and digital marketing.
                Integral solutions for your website, including free web
                hosting.
              </p>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} fullWidth>
              <img style={webImage} src={web} alt="First slide" />
            </Grid>
          </Grid>
        </Carousel.Item>
        <Carousel.Item>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12} fullWidth>
              <h2 style={WebText}>Mobile App Development</h2>
              <p style={text}>
                Design and develop of mobile applications for Android and iOS.
                We create mobile apps developed in React Native and Ionic
                Framework. Also we create and design your application to suit
                your needs.
              </p>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} fullWidth>
              <img style={webImage} src={app} alt="First slide" />
            </Grid>
          </Grid>
        </Carousel.Item>
        <Carousel.Item>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12} fullWidth>
              <h2 style={WebText}>Software Development</h2>
              <p style={text}>
                Software engineers design and create computer systems and
                applications to solve real-world problems.We design and create
                your software to suit your needs.{" "}
              </p>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} fullWidth>
              <img style={webImage} src={software} alt="First slide" />
            </Grid>
          </Grid>
        </Carousel.Item>
      </Carousel>


      {/* <........................................Build Quality.................................................> */}


      <Box backgroundImage={dotsquare}>
        <Typography className={classes.BuildTitle}>
          Build Quality
        </Typography>
        <Typography className={classes.BuildTitle1} >
          <b>Our Special Features </b>
        </Typography>

        <Box style={BoxStyle1}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>

              <Card style={{ width: "90%" }}>
                <img src={Images.Quality} className={classes.IconStyle} />

                <Typography className={classes.CardTitle}>
                  Quality First
                </Typography>

                <Typography className={classes.CasePara}>
                  Our Product Testing professionals or technicians work for
                  every product-based softwares.
                </Typography>
              </Card>

            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>

              <Card style={{ width: "90%" }}>
              <img src={Images.Communication} className={classes.IconStyle} />

                <Typography className={classes.CardTitle}>
                  Communication
                </Typography>

                <Typography className={classes.CasePara}>
                  Our project timelines, budgets, allowance and deliverables with client
                  expectations.
                </Typography>
              </Card>

            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>

              <Card style={{ width: "90%" }}>
              <img src={Images.Reliability} className={classes.IconStyle} />

                <Typography className={classes.CardTitle}>
                  Reliability
                </Typography>

                <Typography className={classes.CasePara}>
                  Quality assurance, compatibility, applicability,
                  efficiency, and process documentation continuance.
                </Typography>
              </Card>

            </Grid>

          </Grid>

        </Box>
      </Box>


      {/* <................................Desktop View of Integration...............................> */}


      <Box className={classes.BoxStyle}>
        <Box style={{ textAlign: "center", paddingTop: "5%" }}>
          <Typography className={classes.IntegrationTital}>
            Integration
          </Typography>
          <Typography className={classes.IntegrationTital1} >We Use the future</Typography>
          <Typography className={classes.IntegrationTital1}>technologies</Typography>
        </Box>
        <div>
          <Box style={{ marginTop: "-5%" }}>
            <img className={classes.Angular}

              src={Images.Angular}
            />
            <img className={classes.Php}

              src={Images.Php}
            />
            <img className={classes.Android}

              src={Images.Android}
            />
            <img className={classes.Aws}

              src={Images.Aws}
            />
            <img className={classes.Swift}

              src={Images.Swift}
            />
            <img className={classes.Net}

              src={Images.Net}
            />
            <img className={classes.Ui}

              src={Images.Ui}
            />
            <img className={classes.Qa}

              src={Images.Qa}
            />
            <img className={classes.App}

              src={Images.App}
            />
            <img className={classes.Mysql}

              src={Images.Mysql}
            />
            <img className={classes.Python}

              src={Images.Python}
            />
            <img className={classes.Digit}

              src={Images.Digit}
            />
          </Box>
        </div>
      </Box>

      {/* <................................Mobile and Tab View of Integration...............................> */}
      <Box className={classes.MobViewBox}>
        <Box style={{ display: "flex",padding:"1rem" }}>
          <img className={classes.MobViewIcon}  src={Images.Angular} />
          <img className={classes.MobViewIcon} src={Images.Php} />
          <img className={classes.MobViewIcon} src={Images.Android} />
          <img className={classes.MobViewIcon} src={Images.Python}/>
          <img className={classes.MobViewIcon} src={Images.Swift} />

        </Box>
        <Box style={{marginTop:"2rem"}}>
         
          <Typography className={classes.IntegrationTital2}> Integration</Typography>
          <Typography className={classes.IntegrationTital3} >We Use the future</Typography>
          <Typography className={classes.IntegrationTital3}>technologies</Typography>
        
        </Box>
        <Box style={{marginTop:"2rem"}}>
         <img className={classes.MobViewIcon}  src={Images.Qa} />
          <img className={classes.MobViewIcon} src={Images.Ui} />
          <img className={classes.MobViewIcon} src={Images.Aws} />
          <img className={classes.MobViewIcon} src={Images.Net}/>
          <img className={classes.MobViewIcon} src={Images.Mysql} />

        </Box>
      </Box>

      {/* <..............................................................................................................> */}


      <Box className={classes.ItBox}>
        <Grid container spacing={2}>
          <Grid item lg={5} md={12} sm={12} xs={12}>
            <img className={classes.ItImage} src={Images.Servicehome} />
          </Grid>
          <Grid item lg={7} md={12} sm={12} xs={12}>
            <Typography className={classes.ItTitle}>
              We Provide All Kinds Of IT-Services
            </Typography>
            <Typography className={classes.ItPara}

            >
              Our software available to suit every need and purpose. we‘ve
              broadly classified application software into- General
              Applications, Business Applications, and Custom Developed
              Applications. Here is the list of different types of application
              software available in synergiHub.
            </Typography>
            <Grid
              container
              spacing={2}
              style={{ marginLeft: "1%", color: "gray" }}
            >
              <Grid item xs={5} style={{ marginTop: "2%" }}>
                <li style={{ fontSize: "1.1rem" }}>Software Solution</li>
              </Grid>
              <Grid item xs={5} style={{ marginTop: "2%" }}>
                <li style={{ fontSize: "1.1rem" }}>Web Solution</li>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginLeft: "1%", color: "gray" }}
            >
              <Grid item xs={5}>
                <li style={{ fontSize: "1.1rem" }}>App Maintenance & Support</li>
              </Grid>
              <Grid item xs={5}>
                <li style={{ fontSize: "1.1rem" }}>
                  Quality Assurance & Testing
                </li>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginLeft: "1%", color: "gray" }}
            >
              <Grid item xs={5}>
                <li style={{ fontSize: "1.1rem" }}>
                  Graphic Solutions prospecting
                </li>
              </Grid>
              <Grid item xs={5}>
                <li style={{ fontSize: "1.1rem" }}>Showcasing success</li>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>

  );
};

export default Home;
