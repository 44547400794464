import { Route, Routes } from "react-router-dom";
import React from "react";
import About from "../About";
import Career from "../Career";
import Contactus from "../Contactus";
import Header from "../Header";
import Home from "../Home";
import Service from "../Service";
import { RoutePaths } from "./RoutePaths";
import PageFooter from "../PageFooter";

const PageRoute = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path={RoutePaths.aboutus} element={<About />} />
        <Route exact path={RoutePaths.service} element={<Service />} />
        <Route exact path={RoutePaths.contactus} element={<Contactus />} />
        <Route exact path={RoutePaths.career} element={<Career />} />
      </Routes>
      <PageFooter/>

    </>
  );
};

export default PageRoute;
