import ANGULAR from "../Image/angular.png";
import PHP from "../Image/php.png";
import ANDROID from "../Image/android.png";
import AWS from "../Image/aws.png";
import SWIFT from "../Image/swift.jpg";
import NET from "../Image/NET_Core_Logo.svg.png";
import UI from "../Image/ui.png";
import QA from "../Image/Qa.png";
import MYSQL from "../Image/mysql.png";
import APP from "../Image/app.jpg";
import DIGIT from "../Image/digit.png";
import PYTHON from "../Image/python.png";
import SERVICEHOME from "../Image/servicehome.svg";
import communication from "../Image/Communication.gif";
import quality from "../Image/Quality.gif";
import reliability from "../Image/Reliability.gif";
import CAREER from "../Image/career.gif";

export const Images = {
    Angular: ANGULAR,
    Php: PHP,
    Android: ANDROID,
    Aws: AWS,
    Swift: SWIFT,
    Net: NET,
    Ui: UI,
    Qa: QA,
    Mysql: MYSQL,
    App: APP,
    Digit: DIGIT,
    Python: PYTHON,
    Servicehome: SERVICEHOME,
    Communication:communication,
    Quality:quality,
    Reliability:reliability,
    Career:CAREER,


}