import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Grid, Card } from "@mui/material";
import About_bg from "../assets/Image/About_bg.jpg";
import Video from "../assets/Image/Tech_Video.mp4";
import Group1 from "../assets/Image/About/Group1.png";
import Group2 from "../assets/Image/About/Group2.png";
import Group3 from "../assets/Image/About/Group3.png";
import Group4 from "../assets/Image/About/Group4.png";
import Group5 from "../assets/Image/About/Group4.png";
import Group6 from "../assets/Image/About/Group6.png";
import Group7 from "../assets/Image/About/Group7.png";
import Group8 from "../assets/Image/About/Group8.png";



const useStyle = makeStyles(() => ({
  Heading: {
    "&.MuiGrid-root": {
      marginInline: "28%",
      margin: "2%",
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: "4rem",
      margin: "auto",
      textAlign: "center",
      marginTop: "1%",
      color: "#243659",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2rem",
         
          textAlign: "center",
          marginTop: "1%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2.5rem",
          margin: "auto",
          marginTop: "1%",

        }
      },
      [`@media screen and (min-width:769px) and (max-width:900px)`]: {
        "&.MuiTypography-root": {
          fontSize: "3.4rem",
          textAlign: "center",
          marginTop: "1%",
        },
      },

      [`@media screen and (min-width:901px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "3.4rem",
          textAlign: "center",
          marginTop: "1%",
        },
      },
    },
  },

  text:{
    "&.MuiTypography-root": {
      textAlign:"center",
      fontSize:"1.5rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          textAlign:"center",
          fontSize:"1rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.4rem",
        },
      },
      
    },
  },
  DriveHeading:{
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: "2rem",
      color: "#084298",
      padding: "3%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
          padding: "5%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.5rem",
          padding: "5%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.8rem",
          padding: "5%",
        },
      },
      
    },
  },

  Video:{
    borderRadius: "2rem",
    width: "90%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width: "100%",
      borderRadius: "1.2rem",

    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width: "100%",
      height:"100%",
      borderRadius: "1.5rem",
    },
    [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
      width: "100%",
      height:"100%",
      borderRadius: "2rem",
    },
  },

  para:{
    "&.MuiTypography-root": {
      paddingBottom: "1rem",
      fontSize: "1.2rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.8rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
         
        },
      },
      
    },
  },
  ThreeTechText:{
    "&.MuiTypography-root": {
      textAlign: "center", 
      fontSize: "2rem", 
      paddingTop: "4rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.4rem", 
          paddingTop: "2rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.6rem", 
          paddingTop: "2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
         
        },
      },
    }
  },
  TechLine:{
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: "2rem",
      color: "#44d43b",
      

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          paddingBottom: "5rem",
          fontSize: "1.4rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          paddingBottom: "4rem",
          fontSize: "1.6rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          paddingBottom: "4rem",
        },
      },
    }
  },
  Box:{
    
    "&.MuiBox-root":{
      marginLeft: "4rem",
      marginTop:"4rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          marginLeft: "2rem",  
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          marginLeft: "2.5rem", 
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
         
        },
      },
    }
   
  },

  Box1:{
    
    "&.MuiBox-root":{
      backgroungColor: "transparent",
      width: "20%",
      marginLeft: "3%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          width: "40%",
          marginLeft: "5%", 
          marginBottom:"2rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          width: "40%",
          marginLeft: "5%", 
          marginBottom:"2.5rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
         
        },
      },
    }
   
  },
  SecondGrid:{
    "&.MuiGrid-root":{
      marginTop: "4rem" ,
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiGrid-root": {
          marginTop:"1rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiGrid-root": {
          marginTop:"1rem" 
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiGrid-root": {
         
        },
      },
    }
  },

  images: {
    width: "60%",
    marginLeft: "2.5rem",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width: "70%",
      marginLeft: "1rem",

    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width: "60%",
      marginLeft: "2.5rem",

    },
    [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
      width: "70%",
      marginLeft: "1.5rem",

    },

  },
  BoxHeading:{
    "&.MuiTypography-root": {
      
      textAlign: "center",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.7rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
         
        },
      },
    }
  },
  main: {
    backgroundImage: `url(${About_bg})`,
    margin: "auto",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "50px",
  },
}));

const About = () => {
  const classes = useStyle();
  return (
    <Card className={classes.main}>
      <Box>
      <Typography className={classes.heading}>Making <span style={{color:"#f97b08"}}>IT</span> happen.</Typography>
      </Box>
      <Typography  style={{}}>
        <Typography className={classes.text} >
          Our talented teams craft the best
        </Typography>
        <Typography className={classes.text} >
          code and design amazing user
        </Typography>
        <Typography className={classes.text}>
          experience for our clients.
        </Typography>
      </Typography>
      <Box style={{ marginTop: "5%" }}>
        <Typography className={classes.DriveHeading}
          style={{
            color:"#f97b08"
          }}
        >
          Drive Digital Revolution Through software development
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={6} md={8} sm={6} xs={12}>
            {" "}
            <video className={classes.Video}
              src={Video}
              autoPlay="true"
             
            />
          </Grid>
          <Grid item lg={6} md={4} sm={6} xs={12}>
            <Typography className={classes.para}>
              {" "}
              <b>synergiHub</b> started with an aspiration to take on
              challenges and solve various aspects of client’s business. We aim
              to serve as a single-stop solution for all your IT requirements
              across business software, website development, digital marketing,
              and IT consultancy. We also partner with consultancy companies to
              execute a part or whole of offshore support on different projects.
              All our offerings are backed by our premium corporate training
              services (as needed) to help you build high-quality teams that
              will be ready for deployment without any additional training.
            </Typography>
            {/* <Typography style={{paddingBottom:"1rem" ,fontSize:"1.2rem"}}>The days of “build it and they will come” are long gone; marketing your website/app in the digital realm often costs less than traditional offline marketing. We, at Allentics, will help you understand, customize, and execute different parts of a digital marketing strategy (SEO, PPC, backlinks, etc.) for maximum impact on the web.</Typography> */}
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginTop: "1rem" }}>
        <Typography className={classes.ThreeTechText}
          
        >
          <b>Why synergiHub</b>
        </Typography>
        <Typography className={classes.TechLine}
        >
          <b>___________</b>
        </Typography>


        {/* <.......................................Box...................................> */}


        <Box className={classes.Box} >
          <Grid container spacing={4}>
            <Box className={classes.Box1} 
              
            >
              <img src={Group1} className={classes.images} />
              <Typography className={classes.BoxHeading}>
                <b>Easy to setup</b>
              </Typography>
              <Typography className={classes.BoxHeading}>
                Get up and running in <br />
                less than a week.
              </Typography>
            </Box>
            <Box
             className={classes.Box1}
            >
              <img src={Group2} className={classes.images} />
              <Typography className={classes.BoxHeading}>
                <b>Scalable</b>
              </Typography>
              <Typography className={classes.BoxHeading}>
                Grow your team as <br />
                your company grows.
              </Typography>
            </Box>
            <Box
              className={classes.Box1}
            >
              <img src={Group3} className={classes.images} />
              <Typography className={classes.BoxHeading}>
                <b>Same time zone</b>
              </Typography>
              <Typography className={classes.BoxHeading}>
                We´re on Eastern <br />
                Standard Time.
              </Typography>
            </Box>

            <Box
              className={classes.Box1}
            >
              <img src={Group4} className={classes.images} />
              <Typography className={classes.BoxHeading}>
                <b>Dedicated</b>
              </Typography>
              <Typography className={classes.BoxHeading}>
              Your processes, your <br />
              team, your culture.
              </Typography>
            </Box>
            
          </Grid>

{/* <..................................Second Grid.......................................> */}

          <Grid container spacing={4} className={classes.SecondGrid} >
            <Box
             className={classes.Box1}
            >
              <img src={Group5} className={classes.images} />
              <Typography className={classes.BoxHeading}>
                <b>Large talent pool</b>
              </Typography>
              <Typography className={classes.BoxHeading}>
                We find the perfect <br />
                fit for your project.
              </Typography>
            </Box>
            <Box
              className={classes.Box1}
            >
              <img src={Group6} className={classes.images} />
              <Typography className={classes.BoxHeading}>
                <b>Similar culture</b>
              </Typography>
              <Typography className={classes.BoxHeading}>
                A team that feels
                <br />
                like in-house.
              </Typography>
            </Box>
            <Box
             className={classes.Box1}
            >
              <img src={Group7} className={classes.images} />
              <Typography className={classes.BoxHeading}>
                <b>Easy contact</b>
              </Typography>
              <Typography className={classes.BoxHeading}>
                Easily contact
                <br />
                to our Teams
              </Typography>
            </Box>
            <Box
             className={classes.Box1}
            >
              <img src={Group8} className={classes.images} />
              <Typography className={classes.BoxHeading}>
                <b>Cost savings</b>
              </Typography>
              <Typography className={classes.BoxHeading}>
                Flat monthly rates
                <br />
                and no hidden fees.
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default About;